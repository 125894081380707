import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";

// Dynamically determine the base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL || // For React
                       process.env.NEXT_PUBLIC_BASE_URL || // For Next.js
                       (isDevelopment
                         ? "http://localhost:6002" // Local Development
                         : "https://catlotus.cl/api"); // Production

// Create the Axios instance
export const serverInstance = axios.create({
  baseURL: `${BASE_URL}/`,
  // Optionally set a timeout
  // timeout: 3000,
});
