import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./Search.module.css";
import Select from "react-select";
import color1 from "../Images/color1.png";
import color2 from "../Images/color2.png";
import color3 from "../Images/color3.png";
import color4 from "../Images/color4.png";
import color5 from "../Images/color5.png";
import color6 from "../Images/color6.png";
import bander1 from "../Images/bander1.png";
import bander2 from "../Images/bander2.png";
import bander3 from "../Images/bander3.png";
import { GiShoppingCart } from "react-icons/gi";
// import bander1 from '../Images/bander1.png'
import foil1 from "../Images/foil1.png";
import foil2 from "../Images/foil2.png";
import foil3 from "../Images/foil3.png";
import searchCat from "../Images/searchCat.png";
import Footer from "../Footer/Footer";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setCartItem } from "../../Redux/adminReducer";
import Swal from "sweetalert2";
//rare
import common from "../Images/rarity/Comun.png";
import mythic from "../Images/rarity/Mitica.png";
import uncommon from "../Images/rarity/Infrecuente.png";
import rare from "../Images/rarity/Rara.png";
import Promocional from "../Images/rarity/Promocional.png";
//foil
import foil from "../Images/search/foil.png";
import bili from "../Images/search/bili.png";
import etched from "../Images/search/etched.png";
import oversized from "../Images/search/oversized.png";
import oversized_foil from "../Images/search/oversized_foil.png";
import gilded from "../Images/search/gilded.png";
import texturized from "../Images/search/texturized.png";
import surge from "../Images/search/surge.png";
import galaxy from "../Images/search/galaxy.png";
import oil_slick from "../Images/search/oil_slick.png";
import signed from "../Images/search/signed.png";
import step_and_compleat from "../Images/search/step-and-compleat.png";
import halo from "../Images/search/halo.png";
import confetti from "../Images/search/confetti.png";
import double_rainbow from "../Images/search/DOUBLE RAINBOW.png";
import sin_stock from "../Images/search/SIN STOCK.png";
import { serverInstance } from "../../axiosInstance";
import Autosuggest from "react-autosuggest"; // Import Autosuggest
import Errors2 from "../Errors/Errors2";
import notFound from "../Images/NotFound.png";
import notDispo from "../Images/Nodisponible.jpg";
import { setSearchC } from "../../Redux/userReducer";
import { BallTriangle } from "react-loader-spinner";
import { BiZoomIn } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, `%27`);
}
const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.userReducer?.activeUser);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const cartItems = useSelector((state) => state?.AdminReducer?.cartItems);
  const searchh = useSelector((state) => state?.userReducer?.search);
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [search, setSearch] = useState("");
  const [stock, setStock] = useState({});
  const [clickedItems, setClickedItems] = useState(
    Object.fromEntries(
      Object.keys(stock).map((key) => [
        key,
        Array(stock[key]?.length || 0).fill(false),
      ])
    )
  );

  const [values, setValues] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  const [total, setTotal] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [editions, setEditions] = useState([]);
  const [set, setSet] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parking, setParking] = useState(true);
  const [alter, setAlter] = useState(false);
  const [tab, setTab] = useState(false);
  const [rand, setRand] = useState(0);
  const [changeNavbar, setChangeNavBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const itemsPerPage = 10; // Set your desired items per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const handlePageChange = (event, newPage) => {
    console.log(newPage, "newPage");
    setCurrentPage(newPage);
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use 'auto' or 'smooth' for animated scrolling
    });
  };

  const changingNavBar = () => {
    if (window.scrollY >= 150) {
      setChangeNavBar(true);
    } else {
      setChangeNavBar(false);
    }
  };
  window.addEventListener("scroll", changingNavBar);
  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      const pathParts = item.split(".");
      const extension = pathParts[pathParts.length - 1];
      const name = pathParts[pathParts.length - 2];
      const src = r(item);
      images[name] = {
        name: name,
        extension: extension,
        src: src,
      };
      // console.log(`Name: ${name}, Extension: ${extension}`);
    });
    return images;
  };

  const manaImages = importAll(
    require.context("../Images/mana", false, /\.(png|jpe?g|svg)$/)
  );

  // Step 3: Create a function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    // If the selected checkbox is already the clicked one, unselect it
    if (selectedCheckbox === value) {
      setSelectedCheckbox(null);
    } else {
      // Otherwise, select the clicked checkbox
      setSelectedCheckbox(value);
    }
  };
  const handleIncrementDecrement = (key, index, operation, q) => {
    console.log(key, index, operation, q);
    setValues((prevCounts) => {
      const updatedCounts = { ...prevCounts };

      if (!updatedCounts[key]) {
        updatedCounts[key] = Array(stock[key].length).fill(1);
      }

      if (operation === "increment") {
        if (values[key]) {
          if (q > values[key][index]) updatedCounts[key][index] += 1;
        } else {
          updatedCounts[key][index] += 1;
        }
      } else if (operation === "decrement") {
        if (values[key]) {
          if (values[key][index] > 0) updatedCounts[key][index] -= 1;
        }
      }
      return { ...updatedCounts };
    });
  };

  const handleSelectChange = (key, index, value) => {
    setValues((prevCounts) => {
      const updatedCounts = { ...prevCounts };

      // Initialize key if it doesn't exist
      if (!updatedCounts[key]) {
        updatedCounts[key] = Array(stock[key].length).fill(1);
      }

      // Update the value for the given key and index
      updatedCounts[key][index] = value;

      return { ...updatedCounts };
    });
  };

  useEffect(() => {
    let count = 0;
    let totalPrice = 0;
    if (values) {
      Object.keys(values)?.map((key) => {
        if (values[key]) {
          values[key]?.map((d, i) => {
            if (d > 0 && stock[key] && stock[key][i]) {
              const item = stock[key][i];
              totalPrice += item.precio * d;
              count += d;
            }
          });
        }
      });
    }

    setPrice(totalPrice);
    setQuantity(count);
    console.log(count, "updatedCounts");
  }, [values]);
  useEffect(() => {
    if (searchh !== "") {
      setSearch("");
      if (page > 1) {
        setPage(1);
      } else {
        handleClick(true);
      }
    }
  }, [searchh]);
  useEffect(() => {
    if (searchh !== "") {
      handleClick(true);
    } else {
      handleClick(false);
    }
  }, [page]);
  useEffect(() => {
    handleSet();
  }, []);
  useEffect(() => {
    if (cartItems) {
      let t = 0;
      cartItems?.map((d) => (t = t + d.precio * d.quantity));
      console.log(t, "ddd");
      setTotal(t);
    }
  }, []);
  const handleClick = (type) => {
    setLoading(true);
    setShowOffcanvas(false);
    setStock([]);
    let k;
    let sser = "";
    if (searchh && type) {
      k = {
        search: searchh,
        set: "",
        foil: "",
        page: page,
        userId:userInfo?.iduser
      };
      sser = searchh;
    } else {
      k = {
        search: search,
        set: set,
        foil: selectedCheckbox,
        page: page,
      };
      sser = search;
      const encodedSuggestionValue = encodeURIComponent(sser).replace(
        /[!'()*]/g,
        (c) => "%" + c.charCodeAt(0).toString(16)
      );
      if (alter) {
        k["AlteredArt"] = alter ? "AlteredArt" : "";
        navigate(
          `/search?searchTerm=${encodedSuggestionValue}&&set=${
            set ? set : null
          }&&foil=${
            selectedCheckbox ? selectedCheckbox : null
          }&&alter=AlteredArt`,
          {
            replace: true,
          }
        );
      } else {
        navigate(
          `/search?searchTerm=${encodedSuggestionValue}&&set=${
            set ? set : null
          }&&foil=${selectedCheckbox ? selectedCheckbox : null}`,
          {
            replace: true,
          }
        );
      }
    }

    serverInstance
      .post("/search/card", k)
      .then((res) => {
        console.log(res.data);

        let updatedCounts = {};
        Object.keys(res.data.stock)?.forEach((key) => {
          const data = res.data.stock[key];
          console.log(key, "keykey", data);
          updatedCounts[key] = Array(data.length).fill(1);
        });
        setValues(updatedCounts);
        const totalLength = Object.values(res.data?.stock).reduce(
          (length, arr) => {
            if (Array.isArray(arr)) {
              length += arr.length;
            }
            return length;
          },
          0
        );
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Use 'auto' or 'smooth' for animated scrolling
        });
        setRand(Math.floor(Math.random() * messages.length));
        // setSearch("");
        // dispatch(setSearchC(""));
        setLoading(false);
        console.log(totalLength);
        setParking(false);
        setTotalPage(res?.data?.totalPages);
        setStock(res.data.stock);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleCart = (item, q, key, index) => {
    let modifiedQ = item?.quantity >= 1 ? q + 1 : q;
    console.log(
      modifiedQ,
      q,
      key,
      index,
      modifiedQ > item.stock || item?.stock == 0
    );
    if (q > 0) {
      if (modifiedQ > item.stock || item?.stock == 0) {
        return Swal.fire("Item is out of stock!", "", "error");
      } else if (modifiedQ >= 1) {
        handleIncrementDecrement(
          key,
          index,
          "increment",
          item?.stock - item?.reservadas
        );
        console.log("increase");
      }
      let cartIt = [...cartItems];
      cartIt = cartItems.filter((d) => d.idcarta != item.idcarta);
      let cartI = cartItems.filter((d) => d.idcarta === item.idcarta);
      console.log(cartI, "cartI");
      item = {
        ...item,
        quantity: cartI?.length > 0 ? modifiedQ : modifiedQ,
      };
      cartIt.push(item);
      localStorage.setItem("cartItem", JSON.stringify(cartIt));
      dispatch(setCartItem(cartIt));
      let t = 0;
      cartIt.map((d) => (t = t + d.precio * d.quantity));
      setTotal(t);
      // Swal.fire(`${item.nombre} added in cata`, "", "success", 1000);
    }
  };

  const handleAutocomplete = (value) => {
    serverInstance
      .post("/search/completar", { id: value })
      .then((res) => {
        const suggestionsArray = res.data.data; // Modify this based on your API response structure
        console.log(res.data.data, "resdata");
        setSuggestions(suggestionsArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSet = () => {
    serverInstance
      .get("/search/getSets", {})
      .then((res) => {
        const suggestionsArray = res.data; // Modify this based on your API response structure
        console.log(res.data, "resdata");
        setEditions(suggestionsArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      dispatch(setSearchC(""));
      if (page > 1) {
        setPage(1);
      } else {
        handleClick(false);
      }
    }
  };
  const autosuggestInputProps = {
    placeholder: "Mystic Remora",
    value: search,
    onChange: (event, { newValue }) => {
      setSearch(newValue);
      handleAutocomplete(newValue);
    },
    onKeyDown: handleEnterKeyPress,
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-container">
      <div className="suggestion">{suggestion}</div>
    </div>
  );
  const messages = [
    "Puedes buscar ediciones completas o tipos de foils con los filtros de búsqueda",
    "Para buscar listas o mazos completos anda a la sección 'Deckbuilder'",
    "¿Te faltó una carta en un pedido? Simplemente agregala al carro y podrás incluirla",
    "¿Tienes cartas que no usas? En la sección 'Compramos tus cartas' verás cómo te podemos hacer una oferta",
    "En 'Hojear cartas' encontrarás distintos criterios de búsqueda y una base de mazos commander",
    "Estamos en marcha blanca de la siguiente versión de la web. Si tienes un problema o sugerencia, por favor escribenos a contacto@catlotus.cl",
  ];

  const generateCardLink = (card) => {
    // Split the card name into two parts using " // "
    const cardNameParts = card?.nombre?.split(" // ");

    // Encode each part of the card name

    const identifier =
      cardNameParts?.length > 1 ? "multiple-parts" : "single-part";

    // Combine the encoded parts, identifier, set name, and collector number into the final link

    let cardLink = null;
    if (cardNameParts?.length > 1) {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.nombre.toLowerCase()) +
        "/" +
        (card?.collector_number?.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
      console.log(cardLink?.split("/")[5]);
    } else {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.nombre.toLowerCase()) +
        "/" +
        (card?.collector_number?.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
    }
    return cardLink;
  };
  const [magnifiedImages, setMagnifiedImages] = useState({});

  const handleZoomClick = (key) => {
    setMagnifiedImages((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the magnified state for the corresponding key
    }));
  };
  return (
    <div>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.searchouter}>
        {/* <div className={styles.tipArea}>
          Tip: <span>Lorem ipsum</span>
        </div> */}
        <div className={styles.searchMain}>
          {["xl"].map((expand) => (
            <Navbar
              key={expand}
              expand={expand}
              className={` ${styles.centerKp} ${styles.maw}`}
            >
              <Container fluid className={styles.namfluid}>
                <Navbar.Brand href="#"> </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                  className={` ${styles.togleBorder} ${styles.maNd}`}
                  onClick={() => setShowOffcanvas(!showOffcanvas)}
                >
                  <div
                    className={styles.carro1}
                    onClick={() => {
                      setTab(false);
                    }}
                  >
                    Filtros
                  </div>
                  <div
                    className={styles.carro1}
                    style={{ marginRight: "5%" }}
                    onClick={() => {
                      setTab(true);
                    }}
                  >
                    Carro
                  </div>
                </Navbar.Toggle>
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                  className={styles.btt}
                  show={showOffcanvas}
                  onHide={() => setShowOffcanvas(false)}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className={styles.noPadding}>
                      {/* <div className=" justify-content-around newB">
                        <div
                          onClick={() => {
                            setTab(false);
                          }}
                          className={tab ? styles.activebtt : styles.activebt}
                        >
                          filter
                        </div>
                        <div
                          onClick={() => {
                            setTab(true);
                          }}
                          className={tab ? styles.activebt : styles.activebtt}
                        >
                          cart
                        </div>
                      </div> */}
                      {tab == false ? (
                        <div className={styles.leftColoum}>
                          <div className={styles.carro}>Filtros</div>
                          <button
                            className={styles.aplicra1}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(setSearchC(""));
                              if (page > 1) {
                                setPage(1);
                              } else {
                                handleClick(false);
                              }
                            }}
                          >
                            Aplicar
                          </button>

                          <div className={styles.inputsArea1}>
                            <div className={styles.edition}>Edición:</div>
                            <div className={styles.reactsSlect}>
                              <select
                                id="setSelect"
                                style={{ maxWidth: "100%" }}
                                onChange={(e) => {
                                  setSet(e.target.value);
                                }}
                              >
                                <option value="">Seleccionar Edición</option>
                                {editions?.map((set) => (
                                  <option
                                    style={{ maxWidth: "200px" }}
                                    key={set.set_id}
                                    value={set.set_id}
                                  >
                                    {set.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className={styles.line}></div>
                            <div
                              className={styles.edition}
                              style={{ marginTop: "15px" }}
                            >
                              Nombre de carta
                            </div>
                            <div className={styles.reactsSlect}>
                              <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={({ value }) => {}}
                                onSuggestionsClearRequested={() => {}}
                                getSuggestionValue={(suggestion) => suggestion} // Modify this based on your API response structure
                                renderSuggestion={renderSuggestion}
                                inputProps={autosuggestInputProps}
                              />
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.arterArea}>
                              <input
                                type="checkbox"
                                checked={alter}
                                className={styles.imgolia}
                                onChange={(e) => {
                                  console.log(e.target.checked);
                                  setAlter(e.target.checked);
                                }}
                              />
                              <div
                                className={styles.edition}
                                style={{ marginLeft: "5px" }}
                              >
                                {" "}
                                Arte alterado
                              </div>
                            </div>
                            <div
                              className={styles.line}
                              style={{ marginTop: "20px" }}
                            ></div>

                            <div className={styles.anglesboX}>
                              <div className={styles.idioma}>
                                Tratamiento foil:
                              </div>

                              {/* Checkbox 1: Etched */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="etched"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "etched"}
                                />
                                <div className={styles.spanol}> Etched</div>
                              </div>

                              {/* Checkbox 2: Oversized */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="oversized"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "oversized"}
                                />
                                <div className={styles.spanol}> Oversized</div>
                              </div>

                              {/* Checkbox 3: Oversized Foil */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="oversized_foil"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={
                                    selectedCheckbox === "oversized_foil"
                                  }
                                />
                                <div className={styles.spanol}>
                                  {" "}
                                  Oversized Foil
                                </div>
                              </div>

                              {/* Checkbox 4: Gilded */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="gilded"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "gilded"}
                                />
                                <div className={styles.spanol}> Gilded</div>
                              </div>

                              {/* Checkbox 5: Texturized */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="texturized"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "texturized"}
                                />
                                <div className={styles.spanol}> Texturized</div>
                              </div>

                              {/* Checkbox 6: Surge */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="surge"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "surge"}
                                />
                                <div className={styles.spanol}> Surge</div>
                              </div>

                              {/* Checkbox 7: Galaxy */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="galaxy"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "galaxy"}
                                />
                                <div className={styles.spanol}> Galaxy</div>
                              </div>

                              {/* Checkbox 8: Oil Slick */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="oil_slick"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "oil_slick"}
                                />
                                <div className={styles.spanol}> Oil Slick</div>
                              </div>

                              {/* Checkbox 9: Signed */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="signed"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "signed"}
                                />
                                <div className={styles.spanol}> Signed</div>
                              </div>

                              {/* Checkbox 10: Step-and-Compleat */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="step_and_compleat"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={
                                    selectedCheckbox === "step_and_compleat"
                                  }
                                />
                                <div className={styles.spanol}>
                                  {" "}
                                  Step-and-Compleat
                                </div>
                              </div>

                              {/* Checkbox 11: Halo */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="halo"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "halo"}
                                />
                                <div className={styles.spanol}> Halo</div>
                              </div>

                              {/* Checkbox 12: Confetti */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="confetti"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckbox === "confetti"}
                                />
                                <div className={styles.spanol}> Confetti</div>
                              </div>

                              {/* Checkbox 13: Double Rainbow */}
                              <div className={styles.chinnoArea}>
                                <input
                                  type="checkbox"
                                  value="double_rainbow"
                                  className={styles.imgolia}
                                  onChange={handleCheckboxChange}
                                  checked={
                                    selectedCheckbox === "double_rainbow"
                                  }
                                />
                                <div className={styles.spanol}>
                                  {" "}
                                  Double Rainbow
                                </div>
                              </div>
                            </div>

                            <button
                              className={styles.aplicra1}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(setSearchC(""));
                                if (page > 1) {
                                  setPage(1);
                                } else {
                                  handleClick(false);
                                }
                              }}
                            >
                              Aplicar
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.endColum1}>
                          <div className={styles.endInner}>
                            <div className={styles.deom}>Carro de compras</div>
                            <div className={styles.elemon}>
                              (
                              {cartItems.reduce(
                                (total, item) => total + item.quantity,
                                0
                              )}
                              elementos)
                            </div>
                            <div className={styles.total}>
                              Subtotal: ${" "}
                              <span style={{ marginLeft: "5px" }}>
                                {Number(total).toLocaleString("es-CL")}
                              </span>
                            </div>
                          </div>
                          <hr className={styles.linee} />
                          {cartItems?.map((d) => {
                            return (
                              <>
                                <div>
                                  {d.quantity}x{` ` + d?.set + `: ` + d.nombre}
                                </div>
                                <br />
                              </>
                            );
                          })}

                          <button
                            className={styles.carroButton}
                            onClick={() => {
                              if (cartItems.length > 0) {
                                navigate("/cart2");
                              }
                              console.log("Clicked");
                            }}
                          >
                            Ir al carro
                          </button>
                        </div>
                      )}
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
          <div className={styles.centercoloum}>
            {Object.keys(stock).length === 0 ? (
              <>
                {/* // <Errors2 /> */}
                {loading ? (
                  <div className={styles.lao}>
                    <BallTriangle
                      height={150}
                      width={150}
                      radius={3}
                      color="#4fa94d"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  </div>
                ) : parking ? (
                  <img src={notFound} alt="" className={styles.gfgf} />
                ) : (
                  <img src={notDispo} alt="" className={styles.gfgf} />
                )}
              </>
            ) : (
              <div className={styles.innerCard}>
                <div
                  className={`dfl justify-content-center ${styles.mobileHide}`}
                >
                  <div className={styles.khl}>{messages[rand]}</div>
                </div>
                {Object.keys(stock).map((key) => {
                  const isMagnified = magnifiedImages[key] || false;
                  return (
                    <>
                      <div className={styles.upperCards}>
                        <div className={`${styles.lore} `}>
                          <div
                            className="uppppp"
                            style={{
                              position: "relative",
                              paddingLeft: "20px",
                            }}
                          >
                            <BiZoomIn className="zome" />
                            <img
                              onClick={() => handleZoomClick(key)}
                              src={
                                isMagnified
                                  ? stock[key][0]?.image_uris?.large
                                  : stock[key][0]?.image_uris?.small
                              }
                              className={`centerImageee ${
                                isMagnified ? "magnifier" : ""
                              }`}
                              alt=""
                            />
                          </div>
                          <div
                            className={`${styles.umezawa} col-md-8 col-lg-8 ${
                              stock[key][0]?.nombre?.length > 40
                                ? "col-xl-3"
                                : "col-xl-3"
                            }`}
                          >
                            <div className={styles.umeHeading}>
                              <Link
                                to={generateCardLink(stock[key][0])}
                                state={{
                                  idd: "",
                                }}
                              >
                                {" "}
                                {stock[key][0]?.nombre}
                              </Link>
                            </div>
                            <div className={styles.umeFrom}>
                              {stock[key][0]?.set_name}
                            </div>
                            {stock[key][0]?.rarity !== undefined ? (
                              stock[key][0]?.rarity === "common" ? (
                                <img
                                  src={common}
                                  className={styles.kann2}
                                  alt=""
                                />
                              ) : stock[key][0]?.rarity === "mythic" ? (
                                <img
                                  src={mythic}
                                  className={styles.kann2}
                                  alt=""
                                />
                              ) : stock[key][0]?.rarity === "uncommon" ? (
                                <img
                                  src={uncommon}
                                  className={styles.kann2}
                                  alt=""
                                />
                              ) : stock[key][0]?.rarity === "rare" ? (
                                <img
                                  src={rare}
                                  className={styles.kann2}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={Promocional}
                                  className={styles.kann2}
                                  alt=""
                                />
                              )
                            ) : (
                              ""
                            )}
                            <div className={styles.numbu}>
                              {stock[key][0]?.mana_cost?.map((d, index) => {
                                // Remove any non-alphanumeric characters (except for '/')
                                const cleanD = d?.replace(/[^A-Za-z0-9/]/g, "");
                                console.log(d, "deckk", cleanD);

                                return (
                                  <React.Fragment key={index}>
                                    {/* Check if the mana cost contains '//' */}
                                    {cleanD.includes("//") ? (
                                      cleanD
                                        .split("//")
                                        .map((part, partIndex) => {
                                          const trimmedPart = part.trim();
                                          return (
                                            <React.Fragment key={partIndex}>
                                              {manaImages[`/${trimmedPart}`] ? (
                                                <img
                                                  src={
                                                    manaImages[
                                                      `/${trimmedPart}`
                                                    ].src
                                                  }
                                                  alt={`Mana Symbol ${trimmedPart} alt`}
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    margin: "0px 1px",
                                                  }}
                                                />
                                              ) : (
                                                <span>{trimmedPart}</span>
                                              )}
                                              {partIndex <
                                                cleanD.split("//").length -
                                                  1 && <span> // </span>}
                                            </React.Fragment>
                                          );
                                        })
                                    ) : (
                                      <>
                                        {manaImages[
                                          `/${cleanD?.replace("/", "")}`
                                        ] ? (
                                          <img
                                            src={
                                              manaImages[
                                                `/${cleanD?.replace("/", "")}`
                                              ].src
                                            }
                                            alt={`Mana Symbol ${cleanD?.replace(
                                              "/",
                                              ""
                                            )} alt`}
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                              margin: "0px 1px",
                                            }}
                                          />
                                        ) : (
                                          <span>{cleanD}</span>
                                        )}
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        {/* <div className={styles.sinStack}>Cartas sin stock</div> */}
                      </div>
                      <div className={styles.centerCard}>
                        <img
                          src={bili}
                          alt=""
                          className={`${styles.bili}  ${styles.mobileHide}`}
                        />
                        <div className={`${styles.mobileShow} ${styles.ner}`}>
                          {stock[key]?.map((row, index) => (
                            <div
                              className={styles.arrangement}
                              style={{
                                borderBottom:
                                  index === stock[key].length - 1
                                    ? "none"
                                    : "1px solid #ccc", // Border-bottom for all items
                              }}
                            >
                              <div className="d-flex flex-column gap-2">
                                <div className="d-flex gap-2">
                                  <div className="d-flex justify-content-center">
                                    <div className={styles.num}>
                                      {row?.estado == 0
                                        ? "NM"
                                        : row?.estado == 1
                                        ? "SP"
                                        : row?.estado == 2
                                        ? "MP"
                                        : row?.estado == 3
                                        ? "HP"
                                        : row?.estado == 4
                                        ? "DMG"
                                        : row?.estado == 5
                                        ? "AL"
                                        : ""}
                                    </div>
                                  </div>
                                  <div className={styles.idio}>
                                    {(row?.idioma).toUpperCase()}
                                  </div>
                                </div>
                                <div
                                  className={styles.foils}
                                  style={{ width: "100%" }}
                                >
                                  {row?.stock < values[key][index] ? (
                                    <img
                                      src={sin_stock}
                                      style={{ width: "100px" }}
                                    />
                                  ) : row?.foil >= 1 && row?.foil <= 13 ? (
                                    row?.foil === 1 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={foil}
                                      />
                                    ) : row?.foil === 2 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={etched}
                                      />
                                    ) : row?.foil === 3 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={oversized}
                                      />
                                    ) : row?.foil === 4 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={oversized_foil}
                                      />
                                    ) : row?.foil === 5 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={gilded}
                                      />
                                    ) : row?.foil === 6 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={texturized}
                                      />
                                    ) : row?.foil === 7 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={surge}
                                      />
                                    ) : row?.foil === 8 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={galaxy}
                                      />
                                    ) : row?.foil === 9 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={oil_slick}
                                      />
                                    ) : row?.foil === 10 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={step_and_compleat}
                                        alt="Step-and-Compleat"
                                      />
                                    ) : row?.foil === 11 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={halo}
                                        alt="Halo"
                                      />
                                    ) : row?.foil === 12 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={confetti}
                                        alt="Confetti"
                                      />
                                    ) : row?.foil === 13 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={double_rainbow}
                                        alt="Double Rainbow"
                                      />
                                    ) : row?.signed === 1 ? (
                                      <img
                                        style={{ width: "100px" }}
                                        src={signed}
                                      />
                                    ) : null
                                  ) : null}
                                </div>
                              </div>
                              <div
                                className="d-flex flex-column gap-2 justify-content-between"
                                style={{ height: "89px" }}
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  Stock{" "}
                                  <div className={styles.dtovk}>
                                    {row?.stock - row?.reservadas}
                                  </div>
                                </div>
                                $ {(row?.precio).toLocaleString("es-CL")}
                              </div>
                              <div
                                className="d-flex flex-column gap-2 justify-content-between align-items-center"
                                style={{ height: "89px" }}
                              >
                                <div>
                                  <select
                                    defaultValue="1"
                                    value={values[key] ? values[key][index] : 1}
                                    onChange={(e) =>
                                      handleSelectChange(
                                        key,
                                        index,
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                  >
                                    {Array.from(
                                      { length: row?.stock - row?.reservadas },
                                      (_, index) => (
                                        <option
                                          key={index + 1}
                                          value={index + 1}
                                        >
                                          {index + 1}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div
                                  className={`${styles.shopp} ${
                                    clickedItems[key] &&
                                    clickedItems[key][index]
                                      ? styles.clicked
                                      : ""
                                  }`}
                                  onClick={() => {
                                    handleCart(
                                      row,
                                      values[key][index],
                                      key,
                                      index
                                    );

                                    setClickedItems((prevClickedItems) => {
                                      const newClickedItems = {
                                        ...prevClickedItems,
                                      };
                                      const newArray = newClickedItems[key]
                                        ? [...newClickedItems[key]]
                                        : Array(stock[key]?.length || 0).fill(
                                            false
                                          );
                                      newArray[index] = true;
                                      newClickedItems[key] = newArray;
                                      return newClickedItems;
                                    });
                                  }}
                                >
                                  <GiShoppingCart
                                    className={styles.shopInner}
                                  />
                                </div>
                              </div>
                              {/* <div className={styles.numberArea}>
                                <div
                                  className={styles.decremet}
                                  style={
                                    0 == values[key] && values[key][index]
                                      ? { cursor: "default" }
                                      : {}
                                  }
                                  onClick={() =>
                                    handleIncrementDecrement(
                                      key,
                                      index,
                                      "decrement",
                                      row?.stock - row?.reservadas
                                    )
                                  }
                                >
                                  -
                                </div>
                                <div className={styles.numberFielkd}>
                                  {values[key] ? values[key][index] : 1}
                                </div>
                                <div
                                  className={styles.increment}
                                  style={
                                    row?.stock - row?.reservadas ==
                                      values[key] && values[key][index]
                                      ? { cursor: "default" }
                                      : {}
                                  }
                                  onClick={() =>
                                    handleIncrementDecrement(
                                      key,
                                      index,
                                      "increment",
                                      row?.stock - row?.reservadas
                                    )
                                  }
                                >
                                  +
                                </div>
                              </div> */}
                            </div>
                          ))}
                        </div>
                        <TableContainer
                          component={Paper}
                          className={styles.mobileHide}
                        >
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            {" "}
                            <TableHead>
                              <TableRow className="tdd">
                                <TableCell align="right">
                                  <div className={styles.topDesign}>Estado</div>{" "}
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.topDesign}>Idioma</div>{" "}
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.topDesign}>
                                    Tratamiento foil
                                  </div>{" "}
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.topDesign}>Stock</div>{" "}
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.topDesign}>Precio</div>{" "}
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.topDesign}>
                                    Agregar
                                  </div>{" "}
                                </TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            {stock[key]?.map((row, index) => (
                              <TableBody>
                                <TableRow
                                  key={row?.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="right">
                                    <div className="d-flex justify-content-center">
                                      <div className={styles.num}>
                                        {row?.estado == 0
                                          ? "NM"
                                          : row?.estado == 1
                                          ? "SP"
                                          : row?.estado == 2
                                          ? "MP"
                                          : row?.estado == 3
                                          ? "HP"
                                          : row?.estado == 4
                                          ? "DMG"
                                          : row?.estado == 5
                                          ? "AL"
                                          : ""}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="right">
                                    <div className={styles.idio}>
                                      {(row?.idioma).toUpperCase()}
                                    </div>
                                  </TableCell>
                                  <TableCell align="right">
                                    {
                                      <div className={styles.foils}>
                                        {row?.stock < values[key][index] ? (
                                          <img
                                            src={sin_stock}
                                            style={{ width: "100px" }}
                                          />
                                        ) : row?.foil >= 1 &&
                                          row?.foil <= 13 ? (
                                          row?.foil === 1 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={foil}
                                            />
                                          ) : row?.foil === 2 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={etched}
                                            />
                                          ) : row?.foil === 3 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={oversized}
                                            />
                                          ) : row?.foil === 4 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={oversized_foil}
                                            />
                                          ) : row?.foil === 5 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={gilded}
                                            />
                                          ) : row?.foil === 6 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={texturized}
                                            />
                                          ) : row?.foil === 7 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={surge}
                                            />
                                          ) : row?.foil === 8 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={galaxy}
                                            />
                                          ) : row?.foil === 9 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={oil_slick}
                                            />
                                          ) : row?.foil === 10 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={step_and_compleat}
                                              alt="Step-and-Compleat"
                                            />
                                          ) : row?.foil === 11 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={halo}
                                              alt="Halo"
                                            />
                                          ) : row?.foil === 12 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={confetti}
                                              alt="Confetti"
                                            />
                                          ) : row?.foil === 13 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={double_rainbow}
                                              alt="Double Rainbow"
                                            />
                                          ) : row?.signed === 1 ? (
                                            <img
                                              style={{ width: "100px" }}
                                              src={signed}
                                            />
                                          ) : null
                                        ) : null}
                                      </div>
                                    }
                                  </TableCell>
                                  <TableCell align="right">
                                    <div className={styles.dtovk}>
                                      {row?.stock - row?.reservadas}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ fontSize: "21px" }}
                                  >
                                    $ {(row?.precio).toLocaleString("es-CL")}
                                  </TableCell>
                                  <TableCell align="right">
                                    <div className={styles.numberArea}>
                                      <div
                                        className={styles.decremet}
                                        style={
                                          0 == values[key] && values[key][index]
                                            ? { cursor: "default" }
                                            : {}
                                        }
                                        onClick={() =>
                                          handleIncrementDecrement(
                                            key,
                                            index,
                                            "decrement",
                                            row?.stock - row?.reservadas
                                          )
                                        }
                                      >
                                        -
                                      </div>
                                      <div className={styles.numberFielkd}>
                                        {values[key] ? values[key][index] : 1}
                                      </div>
                                      <div
                                        className={styles.increment}
                                        style={
                                          row?.stock - row?.reservadas ==
                                            values[key] && values[key][index]
                                            ? { cursor: "default" }
                                            : {}
                                        }
                                        onClick={() =>
                                          handleIncrementDecrement(
                                            key,
                                            index,
                                            "increment",
                                            row?.stock - row?.reservadas
                                          )
                                        }
                                      >
                                        +
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="right">
                                    <div
                                      className={`${styles.shopp} ${
                                        clickedItems[key] &&
                                        clickedItems[key][index]
                                          ? styles.clicked
                                          : ""
                                      }`}
                                      onClick={() => {
                                        handleCart(
                                          row,
                                          values[key][index],
                                          key,
                                          index
                                        );

                                        setClickedItems((prevClickedItems) => {
                                          const newClickedItems = {
                                            ...prevClickedItems,
                                          };
                                          const newArray = newClickedItems[key]
                                            ? [...newClickedItems[key]]
                                            : Array(
                                                stock[key]?.length || 0
                                              ).fill(false);
                                          newArray[index] = true;
                                          newClickedItems[key] = newArray;
                                          return newClickedItems;
                                        });
                                      }}
                                    >
                                      <GiShoppingCart
                                        className={styles.shopInner}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                          </Table>
                        </TableContainer>
                      </div>
                    </>
                  );
                })}
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            )}
          </div>
          <div className={styles.endColum}>
            <div
              className={styles.fx}
              style={{ top: changeNavbar ? "0px" : "" }}
            >
              <strong>
                <h5 style={{ fontSize: "21px" }}>Carro de compras</h5>
              </strong>
              <div>({totalQuantity} elementos)</div>
              <div>Subtotal: ${Number(total).toLocaleString("es-CL")} </div>
              <hr className={styles.linee} />
              {cartItems.map((d) => {
                return (
                  <>
                    <div>
                      {d.quantity}x{` ` + d?.set + `: ` + d.nombre}
                    </div>
                    <br />
                  </>
                );
              })}
              <br />

              <button
                className={styles.ccbutton}
                onClick={() => {
                  if (cartItems.length > 0) {
                    navigate("/cart2");
                  }
                  console.log("Clicked");
                }}
              >
                Ir al carro
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Search;
