import React, { useEffect, useState } from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./DeliveryInformation.module.css";
import Select from "react-select";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";
import { setCartItem } from "../../Redux/adminReducer";
import { validateRut } from "@fdograph/rut-utilities";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const regiones = [
  {
    NombreRegion: "Region Metropolitana de Santiago",
    comuna: [
      "Alhué",
      "Buin",
      "Calera de Tango",
      "Cerrillos",
      "Cerro Navia",
      "Colina",
      "Conchalí",
      "Curacaví",
      "El Bosque",
      "El Monte",
      "Estación Central",
      "Huechuraba",
      "Independencia",
      "Isla de Maipo",
      "La Cisterna",
      "La Florida",
      "La Granja",
      "La Pintana",
      "La Reina",
      "Lampa",
      "Las Condes",
      "Lo Barnechea",
      "Lo Espejo",
      "Lo Prado",
      "Macul",
      "Maipú",
      "María Pinto",
      "Melipilla",
      "Ñuñoa",
      "Padre Hurtado",
      "Paine",
      "Pedro Aguirre Cerda",
      "Peñaflor",
      "Peñalolén",
      "Pirque",
      "Providencia",
      "Pudahuel",
      "Puente Alto",
      "Quilicura",
      "Quinta Normal",
      "Recoleta",
      "Renca",
      "San Bernardo",
      "San Joaquín",
      "San José de Maipo",
      "San Miguel",
      "San Pedro",
      "San Ramón",
      "Santiago",
      "Talagante",
      "Vitacura",
    ],
  },
  {
    NombreRegion: "Region de Arica y Parinacota",
    comuna: ["Arica", "Camarones", "General Lagos", "Putre"],
  },
  {
    NombreRegion: "Region de Tarapaca",
    comuna: [
      "Alto Hospicio",
      "Camiña",
      "Colchane",
      "Huara",
      "Iquique",
      "Pica",
      "Pozo Almonte",
    ],
  },
  {
    NombreRegion: "Region de Antofagasta",
    comuna: [
      "Antofagasta",
      "Calama",
      "María Elena",
      "Mejillones",
      "Ollagüe",
      "San Pedro de Atacama",
      "Sierra Gorda",
      "Taltal",
      "Tocopilla",
    ],
  },
  {
    NombreRegion: "Region de Atacama",
    comuna: [
      "Alto del Carmen",
      "Caldera",
      "Chañaral",
      "Copiapó",
      "Diego de Almagro",
      "Freirina",
      "Huasco",
      "Tierra Amarilla",
      "Vallenar",
    ],
  },
  {
    NombreRegion: "Region de Coquimbo",
    comuna: [
      "Andacollo",
      "Canela",
      "Combarbalá",
      "Coquimbo",
      "Illapel",
      "La Higuera",
      "La Serena",
      "Los Vilos",
      "Monte Patria",
      "Ovalle",
      "Paiguano",
      "Punitaqui",
      "Río Hurtado",
      "Salamanca",
      "Vicuña",
    ],
  },
  {
    NombreRegion: "Region de Valparaiso",
    comuna: [
      "Algarrobo",
      "Cabildo",
      "Calera",
      "Calle Larga",
      "Cartagena",
      "Casablanca",
      "Catemu",
      "Concón",
      "El Quisco",
      "El Tabo",
      "Hijuelas",
      "Isla de Pascua",
      "Juan Fernández",
      "La Cruz",
      "La Ligua",
      "Limache",
      "Llaillay",
      "Los Andes",
      "Nogales",
      "Olmué",
      "Panquehue",
      "Papudo",
      "Petorca",
      "Puchuncaví",
      "Putaendo",
      "Quillota",
      "Quilpué",
      "Quintero",
      "Rinconada",
      "San Antonio",
      "San Esteban",
      "San Felipe",
      "Santa María",
      "Santo Domingo",
      "Valparaíso",
      "Villa Alemana",
      "Viña del Mar",
      "Zapallar",
    ],
  },
  {
    NombreRegion: "Region del Libertador Gral. Bernardo O’Higgins",
    comuna: [
      "Chimbarongo",
      "Chépica",
      "Codegua",
      "Coinco",
      "Coltauco",
      "Doñihue",
      "Graneros",
      "La Estrella",
      "Las Cabras",
      "Litueche",
      "Lolol",
      "Machalí",
      "Malloa",
      "Marchihue",
      "Mostazal",
      "Nancagua",
      "Navidad",
      "Olivar",
      "Palmilla",
      "Paredones",
      "Peralillo",
      "Peumo",
      "Pichidegua",
      "Pichilemu",
      "Placilla",
      "Pumanque",
      "Quinta de Tilcoco",
      "Rancagua",
      "Rengo",
      "Requínoa",
      "San Fernando",
      "San Vicente",
      "Santa Cruz",
    ],
  },
  {
    NombreRegion: "Region del Maule",
    comuna: [
      "Cauquenes",
      "Chanco",
      "Colbún",
      "ConsVtución",
      "Curepto",
      "Curicó",
      "Empedrado",
      "Hualañé",
      "Licantén",
      "Linares",
      "Longaví",
      "Maule",
      "Molina",
      "Parral",
      "Pelarco",
      "Pelluhue",
      "Pencahue",
      "Rauco",
      "ReVro",
      "Romeral",
      "Río Claro",
      "Sagrada Familia",
      "San Clemente",
      "San Javier",
      "San Rafael",
      "Talca",
      "Teno",
      "Vichuquén",
      "Villa Alegre",
      "Yerbas Buenas",
    ],
  },
  {
    NombreRegion: "Región del Biobio",
    comuna: [
      "Alto Biobío",
      "Antuco",
      "Arauco",
      "Bulnes",
      "Cabrero",
      "Cañete",
      "Chiguayante",
      "Chillán",
      "Chillán Viejo",
      "Cobquecura",
      "Coelemu",
      "Coihueco",
      "Concepción",
      "Contulmo",
      "Coronel",
      "Curanilahue",
      "El Carmen",
      "Florida",
      "Hualpén",
      "Hualqui",
      "Laja",
      "Lebu",
      "Los Álamos",
      "Los Ángeles",
      "Lota",
      "Mulchén",
      "Nacimiento",
      "Negrete",
      "Ninhue",
      "Pemuco",
      "Penco",
      "Pinto",
      "Portezuelo",
      "Quilaco",
      "Quilleco",
      "Quillón",
      "Quirihue",
      "Ránquil",
      "San Carlos",
      "San Fabián",
      "San Ignacio",
      "San Nicolás",
      "San Pedro de la Paz",
      "San Rosendo",
      "Santa Bárbara",
      "Santa Juana",
      "Talcahuano",
      "Tirúa",
      "Tomé",
      "Treguaco",
      "Tucapel",
      "Yumbel",
      "Yungay",
      "Ñiquén",
    ],
  },
  {
    NombreRegion: "Region de la Araucania",
    comuna: [
      "Angol",
      "Carahue",
      "Cholchol",
      "Collipulli",
      "Cunco",
      "Curacautín",
      "Curarrehue",
      "Ercilla",
      "Freire",
      "Galvarino",
      "Gorbea",
      "Lautaro",
      "Loncoche",
      "Lonquimay",
      "Los Sauces",
      "Lumaco",
      "Melipeuco",
      "Nueva Imperial",
      "Padre las Casas",
      "Perquenco",
      "Pitrufquén",
      "Pucón",
      "Purén",
      "Renaico",
      "Saavedra",
      "Temuco",
      "Teodoro Schmidt",
      "Toltén",
      "Traiguén",
      "Victoria",
      "Vilcún",
      "Villarrica",
    ],
  },
  {
    NombreRegion: "Region de Los Rios",
    comuna: [
      "Corral",
      "Futrono",
      "La Unión",
      "Lago Ranco",
      "Lanco",
      "Los Lagos",
      "Mariquina",
      "Máfil",
      "Paillaco",
      "Panguipulli",
      "Río Bueno",
      "Valdivia",
    ],
  },
  {
    NombreRegion: "Region de Los Lagos",
    comuna: [
      "Ancud",
      "Calbuco",
      "Castro",
      "Chaitén",
      "Chonchi",
      "Cochamó",
      "Curaco de Vélez",
      "Dalcahue",
      "Fresia",
      "FruVllar",
      "Futaleufú",
      "Hualaihué",
      "Llanquihue",
      "Los Muermos",
      "Maullín",
      "Osorno",
      "Palena",
      "Puerto Montt",
      "Puerto Octay",
      "Puerto Varas",
      "Puqueldón",
      "Purranque",
      "Puyehue",
      "Queilén",
      "Quellón",
      "Quemchi",
      "Quinchao",
      "Río Negro",
      "San Juan de la Costa",
      "San Pablo",
    ],
  },
  {
    NombreRegion: "Region Aisen del Gral. Carlos Ibañez del Campo",
    comuna: [
      "Aisén",
      "Chile Chico",
      "Cisnes",
      "Cochrane",
      "Coihaique",
      "Guaitecas",
      "Lago Verde",
      "O’Higgins",
      "Río Ibáñez",
      "Tortel",
    ],
  },
  {
    NombreRegion: "Region de Magallanes y de la Antartica Chilena",
    comuna: [
      "Antártica",
      "Cabo de Hornos (Ex Navarino)",
      "Laguna Blanca",
      "Natales",
      "Porvenir",
      "Primavera",
      "Punta Arenas",
      "Río Verde",
      "San Gregorio",
      "Timaukel",
      "Torres del Paine",
    ],
  },
];

const DeliveryInformation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);
  console.log(params);
  const cartItems = useSelector((state) => state?.AdminReducer?.cartItems);
  const userInfo = useSelector((state) => state?.userReducer?.activeUser);
  const credito = useSelector((state) => state?.AdminReducer?.creditoo);
  const [rut, setRut] = useState("");
  const [save, setSave] = useState(false);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    rut: "",
    sucursal: "",
    comuna: "",
    region: "",
    calle: "",
    instrucciones: "",
    depto: "",
    torre: "",
    numero: "",
    lable: "",
  });
  const [erro, setErro] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    rut: "",
    sucursal: false,
    comuna: false,
    region: false,
    rut: false,
    calle: false,
    numero: false,
    lable: false,
  });
  const [retiro, setRetiro] = useState(false);
  const [comuna, setComuna] = useState([]);
  const [direccion, setDireccon] = useState([]);
  useEffect(() => {
    if (userInfo) {
      setUser({
        ...user,
        email: userInfo?.email,
        firstName: userInfo?.nombre,
        lastName: userInfo?.apellido,
        phone: userInfo?.telefono,
        rut: userInfo?.rut,
      });
      setErro({ ...erro, rut: !validateRut(userInfo?.rut) });
      setRetiro(params.retiro);
      GetDirec(userInfo?.iduser);
    } else {
      navigate("/login");
    }
  }, []);
  const GetDirec = (id) => {
    serverInstance
      .get(`/user/direcciones/${id}`)
      .then((res) => {
        console.log(res);
        const options = res?.data?.data?.map((item) => ({
          value: item, // Set entire address object as the value
          label: item.label,
        }));

        setDireccon(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [radioSelect, setRadioSelect] = useState(1);
  const validateEmail = (email) => {
    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return EMAIL_REGEX.test(email);
  }
  const radioHandle = () => {
    console.log("radioHandle");
    setRadioSelect(1);
    setErro({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      rut: "",
      sucursal: false,
      comuna: false,
      region: false,
      rut: false,
      calle: false,
      numero: false,
    });
  };
  const radioHandle1 = () => {
    console.log("radioHandle");
    setRadioSelect(2);
    setErro({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      rut: "",
      sucursal: false,
      comuna: false,
      region: false,
      rut: false,
      calle: false,
      numero: false,
    });
  };
  const radioHandle2 = () => {
    setRadioSelect(3);
    setErro({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      rut: "",
      sucursal: false,
      comuna: false,
      region: false,
      rut: false,
      calle: false,
      numero: false,
    });
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    let er = false;
    if (user.email == "") {
      navigate("/login");
      return;
    }
    if (!validateEmail(user.email)) {
      Swal.fire("Ingrese una cuenta de correo válida","","error");
      return;
    }
    if (retiro == "true") {
      await new Promise((resolve) => {
        setErro((prevErro) => {
          const updatedErro = { ...prevErro };
          if (user.rut === "") {
            er = true;
            updatedErro.rut = true;
          }

          resolve(); // Resolve the promise once the state is updated
          return updatedErro;
        });
      });
    } else if (radioSelect === 1) {
      await new Promise((resolve) => {
        setErro((prevErro) => {
          const updatedErro = { ...prevErro };

          if (user.sucursal === "") {
            er = true;
            updatedErro.sucursal = true;
          }
          if (user.region === "") {
            er = true;
            updatedErro.region = true;
          }
          if (user.comuna === "") {
            er = true;
            updatedErro.comuna = true;
          }
          if (user.rut === "") {
            er = true;
            updatedErro.rut = true;
          }

          // If you want to show an error message here, you can do it based on the 'er' value.
          resolve(); // Resolve the promise once the state is updated
          return updatedErro;
        });
      });
    } else if (radioSelect === 2) {
      await new Promise((resolve) => {
        setErro((prevErro) => {
          const updatedErro = { ...prevErro };
          if (user.region === "") {
            er = true;
            updatedErro.region = true;
          }
          if (user.comuna === "") {
            er = true;
            updatedErro.comuna = true;
          }
          if (user.rut === "") {
            er = true;
            updatedErro.rut = true;
          }
          if (user.calle === "") {
            er = true;
            updatedErro.calle = true;
          }
          if (user.numero === "") {
            er = true;
            updatedErro.numero = true;
          }
          resolve(); // Resolve the promise once the state is updated
          return updatedErro;
        });
      });
    }
    if (erro.rut) {
      return Swal.fire("¡Entra en la rutina correcta!", "", "error");
    }
    if (save) {
      if (user?.lable === "") {
        setErro({ ...erro, lable: true });
        return Swal.fire("Ingrese una etiqueta de su dirección", "", "error");
      }
    }
    if (er) {
      return Swal.fire("Llene todos los campos", "", "error");
    } else {
      setLoader(true);
      let b = {
        nombre: user?.firstName,
        apellido: user?.lastName,
        email: user?.email,
        rut: user?.rut,
        celular: user?.phone,
        paymentMethod:
          retiro != "false"
            ? "retiro"
            : radioSelect == 1
            ? "sucursal"
            : radioSelect == 2
            ? "direccion"
            : "user",
        sucursal: user?.sucursal,
        cart: cartItems,
        calle: user?.calle,
        numero: user?.numero,
        comuna: user?.comuna,
        region: user?.region,
        instrucciones: user?.instrucciones,
        depto: user?.depto,
        torre: user?.torre,
        userID: userInfo?.iduser,
        credito: credito,
        save: save,
        label: user?.lable,
      };
      await serverInstance
        .post("/prod/checkout", b)
        .then((res) => {
          console.log(res);
          dispatch(setCartItem([]));
          // Swal.fire(res?.data?.message, "", "success");
          setLoader(false);
          navigate("/order");
        })
        .catch((err) => {
          console.log("in error", err);
          Swal.fire(err?.response?.data?.message || "something went wrong!", "", "error");
          setLoader(false);
        });
    }
  };
  return (
    <>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.formArea}>
        <div
          className={styles.formMian}
          style={retiro == "true" ? { minHeight: "auto" } : {}}
        >
          <div className={styles.maxArea}>
            <div className={styles.titlePara}>Datos para envío</div>
            <div className={styles.nameArea}>
              <div className={styles.namesDiv}>
                <div className={styles.nameHeading}>Nombre</div>
                <input
                  type="name"
                  // placeholder="Ignacio"
                  className={styles.firstName}
                  value={user.firstName}
                  onChange={(e) => {
                    setUser({ ...user, firstName: e.target.value });
                  }}
                />
              </div>
              <div className={styles.namesDiv1}>
                <div className={styles.nameHeading}>Apellido</div>
                <input
                  type="name"
                  // placeholder="Energici"
                  className={styles.firstName}
                  value={user.lastName}
                  onChange={(e) => {
                    setUser({ ...user, lastName: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={styles.nameArea}>
              <div className={styles.namesDiv}>
                <div className={styles.nameHeading}>RUT</div>
                <input
                  type="text"
                  // placeholder="12345678-9"
                  placeholder="343456565"
                  className={styles.firstName}
                  value={user?.rut}
                  onChange={(e) => {
                    setUser({ ...user, rut: e.target.value });
                    setErro({ ...erro, rut: !validateRut(e.target.value) });
                    setRut(validateRut(e.target.value));
                    console.log(validateRut(e.target.value));
                  }}
                  style={erro.rut == true ? { borderColor: "red" } : {}}
                />
              </div>
              <div className={styles.namesDiv1}>
                <div className={styles.nameHeading}>Correo</div>
                <input
                  type="email"
                  placeholder="you@example.com"
                  className={styles.firstName}
                  value={user.email}
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={styles.namesDiv2}>
              <div className={styles.nameHeading}>Celular</div>
              <input
                type="text"
                placeholder="9 Digitos"
                className={styles.firstName}
                value={user.phone}
                onChange={(e) => {
                  setUser({ ...user, phone: e.target.value });
                }}
              />
            </div>
            {retiro === "false" ? (
              <div>
                <div className={styles.selectionArea}>
                  <div className={styles.decompra}>Método de compra</div>
                  <div className={styles.seclection1}>
                    <input
                      type="radio"
                      className={styles.checkInput}
                      name="rad1"
                      defaultChecked
                      checked={radioSelect === 1}
                      onChange={radioHandle}
                    />
                    <div className={styles.sucral}>Sucursal de Chilexpress</div>
                  </div>
                  <div className={styles.seclection1}>
                    <input
                      type="radio"
                      className={styles.checkInput}
                      name="rad1"
                      onChange={radioHandle1}
                      checked={radioSelect === 2}
                    />
                    <div className={styles.sucral}>Direcciōn particular</div>
                  </div>
                  <div className={styles.seclection1}>
                    <input
                      type="radio"
                      className={styles.checkInput}
                      name="rad1"
                      onChange={radioHandle2}
                      checked={radioSelect === 3}
                    />
                    <div className={styles.sucral}>
                      Usar direcciones guardadas
                    </div>
                  </div>
                </div>
                {radioSelect === 1 && (
                  <div className={styles.changeArea}>
                    <div className={styles.debas}>
                      Debes poner el nombre da la sucrsal por como la llama
                      chilespress. Por favor busca la sucursal que quieres en
                      este link: <span style={{ color: "#5FC6FF" }}></span>{" "}
                      Chilexexpress
                    </div>
                    <div
                      className={styles.namesDiv}
                      style={{ marginTop: "21px", marginLeft: "20px" }}
                    >
                      <div className={styles.nameHeading}>
                        Ingrese el nombre de la sucursal
                      </div>
                      <input
                        type="text"
                        placeholder="ej. PICK UP DON PEPE"
                        value={user.sucursal}
                        onChange={(e) => {
                          setUser({ ...user, sucursal: e.target.value });
                          setErro({ ...erro, sucursal: false });
                        }}
                        className={styles.firstName}
                        style={
                          erro.sucursal == true ? { borderColor: "red" } : {}
                        }
                      />
                    </div>
                  </div>
                )}
                {radioSelect === 2 && (
                  <div className={styles.changeArea2}>
                    <div className={styles.callArea}>
                      <div className={styles.callyaChart}>
                        <div className={styles.chalTilt}>Calle</div>
                        <input
                          style={
                            erro?.calle == true ? { borderColor: "red" } : {}
                          }
                          type="text"
                          placeholder="Bello Horizonte"
                          className={styles.horizonta}
                          value={user?.calle}
                          onChange={(e) => {
                            setUser({ ...user, calle: e.target.value });
                            setErro({ ...erro, calle: false });
                          }}
                        />
                      </div>
                      <div className={styles.callyaChart}>
                        <div className={styles.chalTilt}>Numero</div>
                        <input
                          style={
                            erro?.numero == true ? { borderColor: "red" } : {}
                          }
                          type="text"
                          placeholder="809"
                          className={styles.horizonta}
                          value={user?.numero}
                          onChange={(e) => {
                            setUser({ ...user, numero: e.target.value });
                            setErro({ ...erro, numero: false });
                          }}
                        />
                      </div>
                      <div className={styles.callyaChart}>
                        <div className={styles.chalTilt}>Departamento</div>
                        <input
                          type="text"
                          placeholder="809"
                          className={styles.horizonta}
                          value={user?.depto}
                          onChange={(e) => {
                            setUser({ ...user, depto: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.callyaChart}>
                      <div className={styles.chalTilt}>Torre/Block/Villa</div>
                      <input
                        type="text"
                        className={styles.horizonta}
                        value={user?.torre}
                        onChange={(e) => {
                          setUser({ ...user, torre: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                )}
                {radioSelect === 3 && (
                  <Select
                    className={styles.selectPut1}
                    placeholder="Seleccione Comuna"
                    options={direccion}
                    onChange={(e) => {
                      console.log(e);
                      if (e.value?.type == 0) {
                        radioHandle1();
                        let reg;
                        regiones.filter((d) => {
                          if (d.NombreRegion == e.value.region) {
                            reg = d;
                          }
                        });
                        setComuna(reg.comuna);
                        setUser({
                          ...user,
                          region: e.value.region,
                          comuna: e.value.comuna,
                          calle: e.value.calle,
                          numero: e.value.numero,
                          depto: e.value.departamento,
                          torre: e.value.torre,
                        });
                      } else if (e.value?.type == 1) {
                        radioHandle();
                        let reg;
                        regiones.filter((d) => {
                          if (d.NombreRegion == e.value.region) {
                            reg = d;
                          }
                        });
                        setComuna(reg.comuna);
                        setUser({
                          ...user,
                          region: e.value.region,
                          comuna: e.value.comuna,
                          sucursal: e.value.sucursal_chilexpress,
                        });
                      }
                    }}
                  />
                )}
                {radioSelect !== 3 && (
                  <div className={styles.regionArea}>
                    <div className={styles.regionTitleArea}>
                      <div className={styles.regionTitle}>Regiōn</div>
                      <select
                        className={styles.selectt}
                        onChange={(e) => {
                          console.log(e.target.value);
                          let reg;
                          regiones.filter((d) => {
                            if (d.NombreRegion == e.target.value) {
                              reg = d;
                            }
                          });
                          setComuna(reg.comuna);
                          setUser({ ...user, region: e.target.value });
                          setErro({ ...erro, region: false });
                        }}
                        style={
                          erro.region == true ? { borderColor: "red" } : {}
                        }
                        value={user?.region}
                      >
                        <option value="">Seleccione región</option>
                        {regiones.map((d) => {
                          return (
                            <option value={d.NombreRegion}>
                              {d.NombreRegion}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className={styles.regionTitleArea}>
                      <div className={styles.regionTitle}>Comuna</div>
                      <select
                        className={styles.selectt}
                        onChange={(e) => {
                          setUser({ ...user, comuna: e.target.value });
                          setErro({ ...erro, comuna: false });
                        }}
                        style={
                          erro.comuna == true ? { borderColor: "red" } : {}
                        }
                        value={user?.comuna}
                      >
                        <option value="">Seleccione Comuna</option>
                        {comuna?.map((d) => {
                          return <option value={d}>{d}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            <div className={styles.deentrega}>Instucciones de entrega</div>
            <textarea
              className={styles.textLoweer}
              value={user?.instrucciones}
              onChange={(e) => {
                setUser({ ...user, instrucciones: e.target.value });
              }}
            ></textarea>

            {retiro === "false" ? (
              <div
                className={`${styles.newAddress} d-flex flex-row align-items-center justify-content-between flex-wrap gap-3`}
              >
                <div className={styles.selection1Area}>
                  <input
                    type="checkbox"
                    value={save}
                    onChange={(e) => {
                      setSave(e.target.checked);
                    }}
                  />
                  <div className={styles.deentrega1}>
                    Guardar dirección en libreta de direcciones
                  </div>
                </div>
                {save && (
                  <input
                    placeholder="Label your address"
                    className={`${styles.firstName} ${styles.w40}`}
                    onChange={(e) => {
                      setUser({ ...user, lable: e.target.value });
                      setErro({ ...erro, lable: false });
                    }}
                    style={
                      erro.lable == true
                        ? {
                            borderColor: "red",
                            marginTop: "30px",
                            marginLeft: "30px",
                          }
                        : { marginTop: "30px", marginLeft: "30px" }
                    }
                  />
                )}
              </div>
            ) : (
              ""
            )}
            {retiro === "true" ? (
              <div>
                The shipment will be available for pickup in the next 2 business
                days at the Piedra Bruja store in the Manuel Montt metro,
                Providencia. You will receive an email where you will be
                informed that you can withdraw it and the confirmation
                information.
              </div>
            ) : (
              ""
            )}
            <button className={styles.complitarButton} onClick={handleConfirm}>
              {loader ? "Loading..." : "Completar compra"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeliveryInformation;
